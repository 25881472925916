import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardStanislawowDawnaKarczma from '../../../monuments/stanislawow/MonumentCardStanislawowDawnaKarczma'
import MonumentCardPomnikLadzyn from '../../../monuments/stanislawow/MonumentCardPomnikLadzyn'
import MonumentCardPomnikStanislawow from '../../../monuments/stanislawow/MonumentCardPomnikStanislawow'

const StanislawowArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Stanisławów" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Stanisławów</Header1>
        <MonumentCardStanislawowDawnaKarczma />
        <MonumentCardPomnikLadzyn />
        <MonumentCardPomnikStanislawow />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default StanislawowArchive
