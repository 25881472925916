import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardPomnikStanislawow = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/stanislawow/pomnik-gok/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={
        '/gminy/stanislawow/pomnik-mieszkancow-stanislawowa-poleglych-w-latach-1914-1920'
      }
      title={'Pomnik Mieszkańców Stanisławowa poległych w latach 1914-1920'}
    >
      Z inicjatywy pochodzącego ze Stanisławowa kapitana Józefa Boruckiego, na
      tamtejszym Rynku Piaskowym ufundowano i 15 sierpnia 1938 roku odsłonięto
      pomnik poświęcony pamięci Mieszkańców gminy Stanisławów poległych w
      walkach o Niepodległość Ojczyzny w latach 1914-1920.
    </MonumentCard>
  )
}

export default MonumentCardPomnikStanislawow
