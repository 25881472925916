import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardPomnikLadzyn = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/stanislawow/ladzyn-pomnik/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/stanislawow/pomnik-z-1928-roku-w-ladzyniu'}
      title={'Pomnik z 1928 roku w Ładzyniu'}
    >
      W dziesiątą rocznicę wskrzeszenia Państwa Polskiego ufundowano w Ładzyniu
      (ówczesnej miejscowości gminnej) pomnik w postaci kamiennego głazu z
      wkomponowaną tablicą pamiątkową. Umiejscowienie w centralnej części
      miejscowości przy drodze krajowej 50, pomiędzy remizą OSP oraz kapliczką
      maryjną z 1905 roku.
    </MonumentCard>
  )
}

export default MonumentCardPomnikLadzyn
