import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardStanislawowDawnaKarczma = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/stanislawow/dawna-karczma/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/stanislawow/dawna-karczma-w-stanislawowie'}
      title={'Dawna karczma w Stanisławowie'}
    >
      Wielki Gościniec Litewski jest hasłem, które pobudza wyobraźnię każdego,
      kto choć trochę interesuje się historią. Szlak prowadzący z Warszawy do
      Wilna miał ogromne znaczenie dla łączności Korony Polskiej i Wielkiego
      Księstwa Litewskiego. Aby kontakty polityczne, wymiana gospodarcza i
      kulturowa kwitła niezbędne było zaplecze. Jednym z jego śladów na terenie
      powiatu jest stanisławowska karczma.
    </MonumentCard>
  )
}

export default MonumentCardStanislawowDawnaKarczma
